// Type of document (code TD) = Business opportunities

const searchable = [
  { value: '0', label: 'Prior information notice without call for competition' },
  { value: '1', label: 'Corrigendum' },
  { value: '2', label: 'Additional information' },
  { value: '3', label: 'Contract notice' },
  { value: '4', label: 'Prequalification notices' },
  { value: '5', label: 'Request for proposals' },
  { value: '6', label: 'General information' },
  { value: '7', label: 'Contract award notice' },
  { value: 'A', label: 'Prior information notice with call for competition' },
  { value: 'B', label: 'Buyer profile' },
  { value: 'C', label: 'Works concession' },
  { value: 'D', label: 'Design contest' },
  { value: 'E', label: 'Works contracts awarded by the concessionaire' },
  { value: 'F', label: 'Subcontracts notice (in the defence and security fields)' },
  { value: 'G', label: 'European economic interest grouping (EEIG)' },
  { value: 'H', label: 'Services concession' },
  { value: 'I', label: 'Call for expressions of interest' },
  { value: 'J', label: 'Concession award notice' },
  { value: 'K', label: 'Modification of a contract/concession during its term' },
  { value: 'M', label: 'Periodic indicative notice (PIN) with call for competition' },
  { value: 'O', label: 'Qualification system with call for competition' },
  { value: 'P', label: 'Periodic indicative notice (PIN) without call for competition' },
  { value: 'Q', label: 'Qualification system without call for competition' },
  { value: 'R', label: 'Results of design contests' },
  { value: 'S', label: 'European Company / European Cooperative Society' },
  { value: 'Y', label: 'Dynamic purchasing system' },
  { value: 'V', label: 'Voluntary ex ante transparency notice' },
  { value: '8', label: 'Other' },
]

export default searchable
export { searchable as TD }
