import { Container, Typography, Stack, Link, useMediaQuery } from '@mui/material'
import { styled, useTheme, alpha } from '@mui/material/styles'

const FooterContainer = styled(Container)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 0.025),
  padding: theme.spacing(4),
}))

export default function Footer() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <FooterContainer maxWidth={false} disableGutters>
      <Container maxWidth="xl">
        <Stack
          direction={matches ? 'column' : 'row'}
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={6}
        >
          <section style={{ flexBasis: '30%' }}>
            <Typography paragraph variant="h6" color="textSecondary">
              A design exercise by Aitor Pérez
            </Typography>
            <Typography paragraph variant="body1" color="textSecondary">
              This prototype has been built as a design exercise after having been a user of the
              current TED search feature for many weeks.
            </Typography>
            <Typography paragraph variant="body1" color="textSecondary">
              Learn more about the pitfalls addressed, the design process used and the development
              of this prototype at{' '}
              <Link
                color="textSecondary"
                href="https://aitorperez.com/design/tender-search"
                target="_blank"
              >
                the case study
              </Link>
              .
            </Typography>
          </section>
          <section style={{ flexBasis: '30%' }}>
            <Typography paragraph variant="h6" color="textSecondary">
              About TED
            </Typography>
            <Typography paragraph variant="body1" color="textSecondary">
              TED (Tenders Electronic Daily) is the online version of the Supplement to the Official
              Journal of the EU, dedicated to European public procurement.
            </Typography>
            <Typography paragraph variant="body1" color="textSecondary">
              TED publishes 746 thousand procurement award notices a year, including 235 thousand
              calls for tenders which are worth approximately €545 billion.
            </Typography>
          </section>
          <section style={{ flexBasis: '30%' }}>
            <Typography paragraph variant="h6" color="textSecondary">
              More stuff
            </Typography>

            <Typography paragraph variant="body1" color="textSecondary">
              You may check more of my{' '}
              <Link
                color="textSecondary"
                href="https://aitorperez.com/#designer-and-technologist"
                target="_blank"
              >
                design and technology work
              </Link>
              , know me at{' '}
              <Link color="textSecondary" href="https://aitorperez.com" target="_blank">
                aitorperez.com
              </Link>
              , see what I have been working on at my{' '}
              <Link color="textSecondary" href="https://linkedin.com/in/aitorperez" target="_blank">
                LinkedIn profile
              </Link>{' '}
              or check my coding at my{' '}
              <Link color="textSecondary" href="https://github.com/pbaitor" target="_blank">
                Github profile
              </Link>
            </Typography>

            <Typography paragraph variant="body1" color="textSecondary">
              This prototype was built with{' '}
              <Link color="textSecondary" href="https://reactjs.org/">
                React.js
              </Link>{' '}
              and{' '}
              <Link color="textSecondary" href="https://mui.com/">
                MUI
              </Link>
              . Deployed with{' '}
              <Link color="textSecondary" href="https://www.netlify.com/">
                Netlify
              </Link>
              . Illustrations by{' '}
              <Link color="textSecondary" href="https://craftwork.design/">
                Craftwork
              </Link>
              .
            </Typography>
          </section>
        </Stack>
      </Container>
    </FooterContainer>
  )
}
