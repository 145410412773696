import { createTheme, lighten } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: [
      'Work Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    subtitle1: { fontWeight: 500, lineHeight: 1.5 },
    subtitle2: { fontWeight: 500 },
  },
  palette: {
    primary: {
      main: '#003399',
    },
    secondary: {
      main: '#009b01',
    },
    info: {
      // complementary to primary
      main: lighten('#003399', 0.95),
      dark: lighten('#003399', 0.75),
      contrastText: '#003399',
    },
    success: {
      // complementary to secondary
      main: lighten('#009b01', 0.9),
      dark: lighten('#009b01', 0.7),
      contrastText: '#009b01',
    },
  },
})

export default theme
