import { useEffect, useCallback, useState } from 'react'
import debounce from 'lodash/debounce'
import axios from 'axios'

import { Typography, Pagination, Stack, Container as UnstyledContainer } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useSearchContext } from 'src/Context/SearchContext'
import useTEDSearch from 'src/API/useTEDSearch'

import ResultCards from './ResultsCards'
import SortMenu from './SortMenu'

// From the API: Sortable by country CY, deadline DT, doc number ND, value TVH/TVL
// From expert search: The documents displayed can be sorted by document number, country, publication date and deadline
const sortOptions = [
  // { id: 1, sortField: 'CY', reverseOrder: false, label: 'Country (asc)' },
  // { id: 2, sortField: 'CY', reverseOrder: true, label: 'Country (desc)' },
  { id: 3, sortField: 'DT', reverseOrder: true, label: 'Deadline (latest first)' },
  { id: 4, sortField: 'DT', reverseOrder: false, label: 'Deadline (closest first)' },
  { id: 5, sortField: 'ND', reverseOrder: true, label: 'Publication (newest)' },
  { id: 6, sortField: 'ND', reverseOrder: false, label: 'Publication (oldest)' },
  // { id: 7, sortField: 'TVH', reverseOrder: true, label: 'Value (highest)' },
  // { id: 8, sortField: 'TVH', reverseOrder: false, label: 'Value (lowest)' },
]

const Container = styled(UnstyledContainer)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}))

export default function SearchResults() {
  const [filters] = useSearchContext()
  const pageSize = 15
  // const [sortField, setSortField] = useState('ND')
  // const [reverseOrder, setReverseOrder] = useState(true)
  const [sort, setSort] = useState(5)
  const [pageNum, setPageNum] = useState(1)

  const selectedSort = sortOptions.find((el) => el.id === sort)
  const { sortField, reverseOrder } = selectedSort

  const handlePageChange = (event, value) => {
    setPageNum(value)
  }

  const [{ results, isLoading, isError }, postSearch] = useTEDSearch()

  const pages = Math.ceil(results?.total / pageSize)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const doSearch = useCallback(
    debounce((f, p, s, r, n, source) => {
      postSearch(f, p, s, r, n, source)
      if (window) {
        window.scrollTo(0, 0)
      }
    }, 350),
    []
  )

  const handleSortChange = useCallback((id) => {
    setSort(id)
  }, [])

  useEffect(() => {
    setPageNum(1)
  }, [filters])

  useEffect(() => {
    const source = axios.CancelToken.source()
    doSearch(filters, pageSize, sortField, reverseOrder, pageNum, source)
    return () => {
      source.cancel() // clean-up
    }
  }, [doSearch, filters, pageSize, sortField, reverseOrder, pageNum])

  return (
    <Container maxWidth="md">
      {isLoading || isError || !results?.total ? (
        <Typography variant="h6" paragraph>
          Searching opportunities...
        </Typography>
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
          flexWrap="wrap"
          sx={{ marginBottom: 2 }}
        >
          <Typography variant="h6" paragraph>
            {`${Intl.NumberFormat('es-ES').format(results.total || 0)} results`}
          </Typography>
          {Number.isNaN(pages) || pages === 0 ? null : (
            <>
              <Typography variant="h6" paragraph color="textSecondary">
                {`Page ${Intl.NumberFormat('es-ES').format(pageNum)}/${Intl.NumberFormat(
                  'es-ES'
                ).format(pages)}`}
              </Typography>
              <SortMenu selected={selectedSort} options={sortOptions} onChange={handleSortChange} />
            </>
          )}
        </Stack>
      )}
      <ResultCards results={results?.results} isLoading={isLoading} isError={isError} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: 4, marginTop: 2 }}
      >
        {results?.total > pageSize && (
          <Pagination
            size="large"
            color="primary"
            count={pages}
            page={pageNum}
            siblingCount={0}
            onChange={handlePageChange}
          />
        )}
      </Stack>
    </Container>
  )
}
