/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react'

import { TextField as MuiTextField, Typography } from '@mui/material'

import { styled, alpha } from '@mui/material/styles'

import { useSearchParamUpdater } from 'src/Context/SearchContext'

const ScopeTextField = styled(MuiTextField)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  '& .MuiFilledInput-root:before': {
    borderColor: alpha(theme.palette.secondary.main, 0.3),
  },
  '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
    borderColor: alpha(theme.palette.secondary.main, 0.6),
  },
  '& .MuiFilledInput-input': {
    backgroundColor: theme.palette.success.main,
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontWeight: 500,
  },
}))

export default function TextField({ defaultValue = '', label, searchParam }) {
  const [value, setValue] = useState(defaultValue)

  const handleChange = (event) => {
    const { target } = event
    const { value: v } = target
    setValue(v)
  }

  const updateFilter = useSearchParamUpdater(searchParam)

  useEffect(() => {
    updateFilter(value)
  }, [updateFilter, value])

  return (
    <section style={{ width: '100%', marginBottom: 16 }}>
      <Typography variant="subtitle2" display="block" gutterBottom color="secondary">
        {label}
      </Typography>
      <ScopeTextField
        fullWidth
        id={`textfield-${label}`}
        label={false}
        color="secondary"
        variant="filled"
        value={value}
        onChange={handleChange}
      />
    </section>
  )
}
