import { useState } from 'react'
import { pickBy, identity } from 'lodash'
import axios from 'axios'

const baseURL = 'https://ted.europa.eu/api/v2.0/notices/search'
const buildSearchRequest = (params) => new URLSearchParams(params)

const buildTextParam = (val) => (val ? `[${val}*]` : undefined)
const buildMultipleChoiceParam = (val) => (val.length ? `[${val.join(' OR ')}]` : undefined)
// const buildSingleParam = (val) => `${val}`
const buildRangeParam = (val) => {
  const [start, end] = val
  const isStartZero = start === 0
  const isEndInfinite = end === 'INF'
  const prefix = isStartZero ? `[` : `[${isEndInfinite ? `>${start}` : `${start}<`}`
  const suffix = isEndInfinite ? `]` : `${isStartZero ? `<${end}` : `>${end}`}]`
  const joined = `${prefix}${suffix}`
  return joined === '[]' ? undefined : `${prefix}${suffix}`
}

const mapScope = (obj) => {
  const { scope } = obj
  return scope
}

const mapValuesToCCL = (obj) => {
  // TV is range
  // AU, FT are text
  // AA, CY, NC, PC, PR, TD are multiple
  const { TV, AU, FT, AA, CY, NC, PC, PR, TD } = obj
  // console.log('mapping values')
  const CCL = {
    TV: buildRangeParam(TV),
    CY: buildMultipleChoiceParam(CY),
    AA: buildMultipleChoiceParam(AA),
    NC: buildMultipleChoiceParam(NC),
    PC: buildMultipleChoiceParam(PC),
    PR: buildMultipleChoiceParam(PR),
    TD: buildMultipleChoiceParam(TD),
    AU: buildTextParam(AU),
    FT: buildTextParam(FT),
  }
  const cleanedObject = pickBy(CCL, identity)
  return cleanedObject
}

const useTEDSearch = () => {
  const [results, setResults] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [timestamp, setTimestamp] = useState(null)

  const postSearch = async (params, pageSize, sortField, reverseOrder, pageNum, source) => {
    // console.log('starting postSearch')
    // setIsError(false)
    setIsLoading(true)

    const mappedScope = mapScope(params)
    const mappedParams = mapValuesToCCL(params)
    // console.log(mappedScope)
    // console.log(mappedParams)

    const replace = /&/gi
    const searchParams = buildSearchRequest(mappedParams).toString().replace(replace, '%20AND%20')
    // console.log(searchParams)

    // MA (Main activity), DT (Deadline), ND (Notice number), OJ (Issue number), PD (publication date)
    const fields = [
      'TVH', // Total value H?
      'TVL', // Total value L?
      'AA',
      'CY',
      'NC',
      'PC',
      'PR',
      'TD', // Next ones are non-searchable
      'MA',
      'DT',
      'ND',
      'OJ',
      'CONTENT',
      'PD',
    ].join(',')

    const urlToFetch = `${baseURL}?fields=${fields}&pageSize=${pageSize}&pageNum=${pageNum}&sortField=${sortField}&reverseOrder=${reverseOrder}&scope=${mappedScope}&q=${searchParams}`
    // console.log(urlToFetch)

    return axios
      .get(`https://api.allorigins.win/get?url=${encodeURIComponent(urlToFetch)}`, {
        cancelToken: source.token,
      })
      .then(({ data: { contents, status } }) => {
        if (status?.http_code === 200) {
          const parsed = JSON.parse(contents)
          // console.log(parsed.took)
          // console.log(parsed)
          setResults(parsed)
          setIsError(false)
        } else {
          setResults({})
          setIsError(true)
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsError(true)
        }
        setResults({})
        // console.log(error)
      })
      .then(() => {
        setIsLoading(false)
        setTimestamp(new Date())
      })

    /*
    return axios
      .get(`https://api.allorigins.win/get?url=${encodeURIComponent(urlToFetch)}`)
      .then(({ data: { contents } }) => {
        const parsed = JSON.parse(contents)
        console.log(parsed.took)
        setResults(parsed)
      })
      .catch((error) => {
        setIsError(true)
        console.log(error)
      })
      .then(() => {
        setIsLoading(false)
        setTimestamp(new Date())
      })
      */
  }

  return [{ results, isLoading, isError, timestamp }, postSearch]
}

export default useTEDSearch
