import OPTIONS from 'src/API/models/pc'
import MultipleSelection from './MultipleSelection'

export default function CommonProcurementVocabulary() {
  return (
    <MultipleSelection
      label="Common Procurement Vocabulary"
      anyLabel="Any subject"
      maxItems={1}
      options={OPTIONS}
      searchParam="PC"
    />
  )
}
