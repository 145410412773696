/* eslint-disable react/prop-types */

import UnstyledCard from '@mui/material/Card'
import UnstyledCardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'

import UnstyledTooltip, { tooltipClasses } from '@mui/material/Tooltip'

import Stack from '@mui/material/Stack'

import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import LinkIcon from '@mui/icons-material/Link'

import ScheduleIcon from '@mui/icons-material/Schedule'
import EuroIcon from '@mui/icons-material/Euro'
import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone'
import Grid3x3Icon from '@mui/icons-material/Grid3x3'

import UnstyledSkeleton from '@mui/material/Skeleton'

import { styled, alpha } from '@mui/material/styles'

import ErrorState from './Error'
import EmptyState from './Empty'

const Card = styled(UnstyledCard)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: `0px 2px 4px -1px ${alpha(theme.palette.primary.main, 0.1)}, 0px 4px 5px 0px ${alpha(
    theme.palette.primary.main,
    0.05
  )}, 0px 1px 10px 0px ${alpha(theme.palette.primary.main, 0.025)}`,
  '& .MuiCardContent-root': {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  '& .MuiCardActions-root>:not(:first-of-type)': {
    marginLeft: 'unset',
  },
}))

const CardActions = styled(UnstyledCardActions)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  flexWrap: 'wrap',
}))

const Tooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <UnstyledTooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.secondary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

const Skeleton = styled(UnstyledSkeleton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
}))

const BlackSkeleton = styled(UnstyledSkeleton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.text.primary, 0.05),
}))

const formatCurrency = (value) =>
  Number.isNaN(Number.parseFloat(value))
    ? 'Not specified'
    : new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value)

const getBuyerName = (value) => {
  const decoded = Buffer.from(value, 'base64').toString()
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(decoded, 'text/xml')
  const description = xmlDoc.getElementsByTagName('OFFICIALNAME')[0].childNodes[0].nodeValue
  return description
}

const getTitle = (value) => {
  // OBJECT_CONTRACT or TITLE_CONTRACT
  const decoded = Buffer.from(value, 'base64').toString()
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(decoded, 'text/xml')
  const title = xmlDoc.querySelectorAll('OBJECT_CONTRACT TITLE,TITLE_CONTRACT')[0].childNodes[0]
    .textContent
  // const translated = xmlDoc.querySelectorAll('ML_TI_DOC[LG="EN"]>TI_TEXT')[0].textContent
  return title
}

const getTenderDocumentsURL = (value) => {
  // URL_DOCUMENT or IA_URL_ETENDERING or URL_BUYER (in this order)
  const decoded = Buffer.from(value, 'base64').toString()
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(decoded, 'text/xml')
  const urlDocument = xmlDoc.querySelectorAll('URL_DOCUMENT')[0]?.textContent
  const urlEtendering = xmlDoc.querySelectorAll('IA_URL_ETENDERING')[0]?.textContent
  const urlBuyer = xmlDoc.querySelectorAll('URL_BUYER')[0]?.textContent

  return urlDocument || urlEtendering || urlBuyer
}

/*
const columns = [
  {
    key: 'ND',
    label: 'Document',
    getValue: (value) => value,
    align: 'left',
    width: '8%',
    skeleton: [0],
  },
  {
    key: 'content',
    label: 'Contract',
    getValue: (value) => getTitle(value),
    align: 'left',
    width: '45%',
    skeleton: [0, 1, 2],
  },
  {
    key: 'content',
    label: 'Buyer',
    getValue: (value) => getBuyerName(value),
    align: 'left',
    width: '20%',
    skeleton: [0, 1],
  },
  {
    key: 'DT',
    label: 'Deadline',
    getValue: (value) => value,
    align: 'left',
    width: '8%',
    skeleton: [0],
  },
  {
    key: 'TVH',
    label: 'Value',
    getValue: (value) => formatCurrency(value),
    align: 'left',
    width: '12%',
    skeleton: [0],
  },
]
*/

export default function ResultCards({ results, isLoading, isError }) {
  return (
    <>
      {/* loading state */}
      {isLoading &&
        [0, 1, 2, 3, 4, 5, 6].map((i) => (
          <Card
            elevation={4}
            key={i}
            sx={{
              opacity: 1 - 0.18 * i,
            }}
          >
            <CardContent>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                <Skeleton width="100%" />
                <Skeleton width="25%" />
              </Typography>
              <Typography variant="body1" paragraph>
                <BlackSkeleton width="100%" />
                <BlackSkeleton width="100%" />
                <BlackSkeleton width="50%" />
              </Typography>
            </CardContent>
          </Card>
        ))}

      {/* error state */}
      {!isLoading && isError && <ErrorState />}

      {/* empty state */}
      {!isLoading && !isError && results?.length === 0 && <EmptyState />}

      {/* normal state */}
      {!isLoading &&
        !isError &&
        results?.length > 0 &&
        results.map((row) => (
          <Card elevation={4} key={row.ND}>
            <CardContent>
              <Typography variant="subtitle1" color="primary" gutterBottom>
                {getBuyerName(row.content)}
              </Typography>
              <Typography variant="body1" paragraph>
                {getTitle(row.content)}
              </Typography>
            </CardContent>
            <CardActions>
              <Tooltip title="Total contract value">
                <Stack direction="row" alignItems="center">
                  <EuroIcon color="default" fontSize="small" sx={{ marginRight: 0.5 }} />
                  <Typography variant="subtitle2" color="textPrimary" sx={{ marginRight: 4 }}>
                    {formatCurrency(row.TVH)}
                  </Typography>
                </Stack>
              </Tooltip>

              <Tooltip title="Deadline for proposal submission">
                <Stack direction="row" alignItems="center">
                  <ScheduleIcon fontSize="small" color="default" sx={{ marginRight: 0.5 }} />
                  <Typography variant="subtitle2" color="textPrimary" sx={{ marginRight: 4 }}>
                    {row.DT}
                  </Typography>
                </Stack>
              </Tooltip>

              <Tooltip title="Publication number">
                <Stack direction="row" alignItems="center">
                  <Grid3x3Icon fontSize="small" color="default" sx={{ marginRight: 0.5 }} />
                  <Typography variant="subtitle2" color="textPrimary" sx={{ marginRight: 4 }}>
                    {row.ND}
                  </Typography>
                </Stack>
              </Tooltip>

              <div style={{ flexGrow: 1 }} />

              <Tooltip title="View publication">
                <IconButton
                  aria-label="view notice"
                  color="default"
                  component="a"
                  href={`https://ted.europa.eu/udl?uri=TED:NOTICE:${row.ND}:TEXT:EN:HTML`}
                  target="_blank"
                >
                  <LinkIcon />
                </IconButton>
              </Tooltip>

              {getTenderDocumentsURL(row.content) && (
                <Tooltip title="Access tender documents">
                  <IconButton
                    aria-label="view tender documents"
                    color="primary"
                    component="a"
                    href={getTenderDocumentsURL(row.content)}
                    target="_blank"
                  >
                    <FolderTwoToneIcon />
                  </IconButton>
                </Tooltip>
              )}
            </CardActions>
          </Card>
        ))}
    </>
  )
}
