import OPTIONS from 'src/API/models/aa'
import MultipleSelection from './MultipleSelection'

export default function TypeOfBuyer() {
  return (
    <MultipleSelection
      label="Type of buyer"
      anyLabel="All buyers"
      maxItems={2}
      options={OPTIONS}
      searchParam="AA"
    />
  )
}
