import OPTIONS from 'src/API/models/nc'
import MultipleSelection from './MultipleSelection'

export default function TypeOfContract() {
  return (
    <MultipleSelection
      label="Type of contract"
      anyLabel="All types"
      maxItems={4}
      options={OPTIONS}
      searchParam="NC"
    />
  )
}
