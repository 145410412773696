import { Typography } from '@mui/material'

import { styled } from '@mui/material/styles'

import logo from 'src/logo.svg'

const Wrapper = styled('header')(({ theme }) => ({
  padding: theme.spacing(2, 4),
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    padding: 0,
    marginBottom: theme.spacing(2),
    '& img': { padding: theme.spacing(2) },
    '& h2': { fontSize: '2.5rem' },
  },
}))

const Heading = styled('section')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

export default function Header() {
  return (
    <Wrapper>
      <img src={logo} height="72" alt="logo" />
      <Heading>
        <Typography variant="h2">Tenders Electronic Daily</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Supplement to the Official Journal of the EU
        </Typography>
      </Heading>
    </Wrapper>
  )
}
