/* eslint-disable react/prop-types */
import OPTIONS from 'src/API/models/scope'
import SingleSelection from './SingleSelection'

export default function Country({ fullWidth }) {
  return (
    <SingleSelection
      label="Publications"
      options={OPTIONS}
      defaultValue={1}
      searchParam="scope"
      fullWidth={fullWidth}
    />
  )
}
