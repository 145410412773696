/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react'

import { Chip, Typography } from '@mui/material'

import { styled, alpha } from '@mui/material/styles'

import { useSearchParamUpdater } from 'src/Context/SearchContext'

const ScopeChip = styled(Chip)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  '&.MuiChip-colorPrimary:hover': {
    backgroundColor: alpha(theme.palette.primary.dark, 1),
  },
  '&.MuiChip-colorInfo:hover, &.MuiChip-colorInfo.Mui-focusVisible': {
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
  },
}))

export default function MultipleSelection({
  options = [],
  defaultValue,
  label,
  searchParam,
  fullWidth,
}) {
  const [value, setValue] = useState(defaultValue)

  const handleClick = (v) => {
    setValue(v)
  }

  const updateFilter = useSearchParamUpdater(searchParam)

  useEffect(() => {
    updateFilter(value)
  }, [updateFilter, value])

  return (
    <section
      style={{
        maxWidth: '100%',
        width: fullWidth ? '100%' : 'auto',
        marginBottom: 16,
        marginLeft: 0,
      }}
    >
      <Typography variant="subtitle2" display="block" gutterBottom color="primary">
        {label}
      </Typography>
      {options.map((el) => (
        <ScopeChip
          color={value === el.value ? 'primary' : 'info'}
          size="small"
          key={el.value}
          label={el.label}
          onClick={() => handleClick(el.value)}
          sx={{ maxWidth: '100%', marginRight: 0.5 }}
        />
      ))}
    </section>
  )
}
