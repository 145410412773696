import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import Paper from '@mui/material/Paper'

import { styled, lighten } from '@mui/material/styles'
import { ReactComponent as EmptyState } from './logged_out.svg'

const StyledEmptyState = styled(EmptyState)(({ theme }) => ({
  '& path, & polygon': {
    stroke: theme.palette.primary.main,
  },
  '& .st0': {
    fill: theme.palette.primary.main,
  },
  '& .st7, & .st6': {
    fill: theme.palette.secondary.main,
    stroke: theme.palette.secondary.main,
  },
  '& .st4': {
    stroke: lighten(theme.palette.secondary.main, 0.7),
    fill: lighten(theme.palette.secondary.main, 0.7),
  },
  '& .st5': {
    stroke: lighten(theme.palette.secondary.main, 0),
    fill: lighten(theme.palette.secondary.main, 0),
  },
  '& .st3': {
    fill: lighten(theme.palette.primary.main, 0.4),
    stroke: lighten(theme.palette.primary.main, 0.4),
  },
}))

const Empty = () => (
  <Paper elevation={0}>
    <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2}>
      <StyledEmptyState sx={{ maxWidth: 360, height: 'auto', padding: 1 }} />
      <Typography variant="body2" color="textSecondary">
        Something really bad happend with your search
      </Typography>
    </Stack>
  </Paper>
)

export default Empty
