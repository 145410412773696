/* eslint-disable react/prop-types */
import OPTIONS from 'src/API/models/cy'
import MultipleSelection from './MultipleSelection'

export default function Country({ fullWidth }) {
  return (
    <MultipleSelection
      label="Countries"
      anyLabel="Any country"
      maxItems={6}
      options={OPTIONS}
      defaultValue={['ES']}
      searchParam="CY"
      fullWidth={fullWidth}
    />
  )
}
