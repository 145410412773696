import { Typography, Stack, Container as UnstyledContainer } from '@mui/material'

import { styled } from '@mui/material/styles'

import TypeOfContract from './TypeOfContract'
import TypeOfProcedure from './TypeOfProcedure'
import TypeOfBuyer from './TypeOfBuyer'
import CommonProcurementVocabulary from './CommonProcurementVocabulary'
import ContractValue from './ContractValue'
import BuyerName from './BuyerName'
import Description from './Description'

const Container = styled(UnstyledContainer)(({ theme }) => ({
  padding: theme.spacing(2),
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('lg')]: {
    marginLeft: theme.spacing(2),
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
  },
}))

export default function RefineSearch() {
  return (
    <Container maxWidth="xs" sx={{ marginBottom: 6 }}>
      <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
        <Typography variant="h6" color="secondary">
          Refine your search
        </Typography>
        <Description />
        <ContractValue />
        <TypeOfContract />
        <TypeOfBuyer />
        <BuyerName />
        <TypeOfProcedure />
        <CommonProcurementVocabulary />
      </Stack>
    </Container>
  )
}
