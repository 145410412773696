import { Drawer } from '@mui/material'

import { styled, alpha } from '@mui/material/styles'

const ScopeDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: alpha(theme.palette.secondary.light, 0.5),
  },
  '& .MuiPaper-root': {
    borderTopColor: '#76cb41',
    borderTopWidth: theme.spacing(0.5),
    borderTopStyle: 'solid',
    padding: theme.spacing(4),
    height: '50vh',
    background: theme.palette.secondary.main,
    color: theme.palette.success.main,
  },
  /*
  '& .MuiContainer-root': { height: '100%' },
  '& .MuiGrid-root': { maxHeight: '75%' }, */
  '& .MuiFormControlLabel-root': { alignItems: 'flex-start' },
  '& .MuiFormControlLabel-label': {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  '& .MuiCheckbox-root': {
    color: theme.palette.secondary.light,
    marginTop: theme.spacing(-1),
  },
  '& .Mui-checked': {
    color: theme.palette.success.main,
  },
}))

export default ScopeDrawer
