import { Typography, Stack, Container as UnstyledContainer, useMediaQuery } from '@mui/material'

import { styled, useTheme } from '@mui/material/styles'

import BusinessOpportunity from './BusinessOpportunity'
import Country from './Country'
import Publication from './Publication'

const Container = styled(UnstyledContainer)(({ theme }) => ({
  padding: theme.spacing(2),
  marginLeft: theme.spacing(2),

  [theme.breakpoints.down('lg')]: {
    marginLeft: theme.spacing(0),
  },

  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
  },
}))

export default function RefineSearch() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <Container maxWidth="xs">
      <Stack
        direction={matches ? 'row' : 'column'}
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={matches ? 4 : 1}
        flexWrap="wrap"
      >
        <Typography variant="h6" color="primary" sx={{ width: matches ? '100%' : 'initial' }}>
          Set the scope
        </Typography>

        <BusinessOpportunity fullWidth={matches} />
        <Country fullWidth={matches} />
        <Publication fullWidth={matches} />
      </Stack>
    </Container>
  )
}
