/* eslint-disable react/prop-types */
import OPTIONS from 'src/API/models/td'
import MultipleSelection from './MultipleSelection'

export default function BusinessOpportunity({ fullWidth }) {
  return (
    <MultipleSelection
      label="Business Opportunities"
      anyLabel="All business opportunities"
      maxItems={2}
      options={OPTIONS}
      defaultValue={['3']}
      searchParam="TD"
      fullWidth={fullWidth}
    />
  )
}
