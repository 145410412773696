/* eslint-disable react/prop-types */
import { createContext, useContext, useState, useCallback } from 'react'

const SearchContext = createContext()

function SearchProvider(props) {
  const { children } = props
  const [filters, setFilters] = useState({})
  return <SearchContext.Provider value={[filters, setFilters]}>{children}</SearchContext.Provider>
}

function useSearchContext() {
  const [filters] = useContext(SearchContext)
  return [filters]
}

function useSearchParamUpdater(searchParam) {
  const [, setFilters] = useContext(SearchContext)
  const updateFilter = useCallback(
    (value) => {
      setFilters((prev) =>
        value ? { ...prev, [searchParam]: value } : { ...prev, [searchParam]: undefined }
      )
    },
    [searchParam, setFilters]
  )
  return updateFilter
}

export { useSearchContext, useSearchParamUpdater, SearchProvider }
