import { useState, useEffect } from 'react'
import { Slider, sliderClasses, Typography, Box } from '@mui/material'

import { styled, alpha, lighten } from '@mui/material/styles'

import { useSearchParamUpdater } from 'src/Context/SearchContext'

const RefineSlider = styled(Slider)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  paddingTop: 0,
  height: 8,
  color: lighten(theme.palette.secondary.main, 0.7),
  [`& .${sliderClasses.mark}`]: {
    width: theme.spacing(0.5),
    height: theme.spacing(0.5),
    borderRadius: theme.spacing(0.25),
    display: 'none',
  },
  '& .MuiSlider-markLabel': {
    color: alpha(theme.palette.secondary.light, 0.7),
    top: theme.spacing(2),
  },
  '& .MuiSlider-markLabelActive': {
    color: theme.palette.secondary.main,
  },
  [`& .${sliderClasses.thumb}`]: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: theme.spacing(0.5),
    borderColor: 'white',
    backgroundColor: theme.palette.secondary.main,
  },
  '@media (pointer: coarse)': {
    paddingTop: 0,
    paddingBottom: 13,
  },
  [`& .${sliderClasses.track}`]: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

const marks = [
  {
    value: 0,
    label: '0',
    search: 0,
  },
  {
    value: 20,
    label: '500k',
    search: 500000,
  },
  {
    value: 40,
    label: '1M',
    search: 1000000,
  },
  {
    value: 60,
    label: '3M',
    search: 3000000,
  },
  {
    value: 80,
    label: '12M',
    search: 12000000,
  },
  {
    value: 100,
    label: 'Any',
    search: 'INF',
  },
]

function valuetext(value) {
  return `${value} €`
}

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1
}

const minDistance = 20

export default function ContractValue() {
  const [value, setValue] = useState([0, 100])

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance)
        setValue([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        setValue([clamped - minDistance, clamped])
      }
    } else {
      setValue(newValue)
    }
  }

  const updateFilter = useSearchParamUpdater('TV')

  useEffect(() => {
    updateFilter(value.map((val) => marks.find((el) => el.value === val).search))
  }, [updateFilter, value])

  return (
    <section style={{ width: '100%', marginBottom: 16 }}>
      <Typography variant="subtitle2" display="block" gutterBottom color="secondary">
        Contract value
      </Typography>
      <Box sx={{ paddingLeft: 1, paddingRight: 1 }}>
        <RefineSlider
          getAriaLabel={() => 'Range of values'}
          color="secondary"
          size="small"
          defaultValue={20}
          valueLabelFormat={valueLabelFormat}
          getAriaValueText={valuetext}
          valueLabelDisplay="off"
          marks={marks}
          step={null}
          value={value}
          onChange={handleChange}
        />
      </Box>
    </section>
  )
}
