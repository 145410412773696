// Common Procurement Vocabulary (code PC)

const searchable = [
  { value: 'agfo', label: 'Agriculture and Food' },
  { value: 'comp', label: 'Computer and Related Services' },
  { value: 'core', label: 'Construction and Real Estate' },
  { value: 'dese', label: 'Defence and security' },
  { value: 'educ', label: 'Education' },
  { value: 'ener', label: 'Energy and Related Services' },
  { value: 'ensa', label: 'Environment and Sanitation' },
  { value: 'fina', label: 'Finance and Related Services' },
  { value: 'mapr', label: 'Materials and Products' },
  { value: 'mior', label: 'Mining and Ores' },
  { value: 'serv', label: 'Other Services' },
  { value: 'prpu', label: 'Printing and Publishing' },
  { value: 'reco', label: 'Research and Development' },
  { value: 'teeq', label: 'Technology and Equipment' },
  { value: 'tran', label: 'Transport and Related Services' },
]

export default searchable
export { searchable as PC }
