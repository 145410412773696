// Search scope (API parameter "scope") 1 for 'Last edition’; 2 for 'Active notices’; 3 for ‘All’

const scope = [
  { value: 3, label: 'All' },
  { value: 1, label: 'Last edition' },
  { value: 2, label: 'Active notices' },
]

export default scope
export { scope }
