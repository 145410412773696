import { Container } from '@mui/material'

import { ThemeProvider, styled } from '@mui/material/styles'
import tedTheme from './Theme'

import { Header, Main, Footer } from './Components/Layout'
import { SearchProvider } from './Context/SearchContext'

const AppContainer = styled(Container)(() => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}))

function App() {
  return (
    <ThemeProvider theme={tedTheme}>
      <SearchProvider>
        <AppContainer maxWidth={false} disableGutters>
          <Header />
          <Main />
          <Footer />
        </AppContainer>
      </SearchProvider>
    </ThemeProvider>
  )
}

export default App
