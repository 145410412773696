/* eslint-disable react/prop-types */

import { useState } from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.primary.main,
    boxShadow: `0px 0px 0px 0px ${alpha(
      theme.palette.primary.main,
      0.1
    )}, 0px 10px 15px -3px ${alpha(theme.palette.primary.main, 0.15)}, 0px 4px 6px -2px ${alpha(
      theme.palette.primary.main,
      0.3
    )}`,

    '& .MuiMenu-list': {
      padding: '0',
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
      '+.MuiDivider-root': {
        margin: 0,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.primary.light,
        marginLeft: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity / 2
        ),
      },
    },
  },
}))

export default function SortMenu({ selected, options, onChange }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSelect = (f, o) => {
    handleClose()
    onChange(f, o)
  }

  return (
    <div>
      <Button
        id="sort-button"
        aria-controls="sort-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        color="primary"
        size="small"
        sx={{ borderRadius: 8, top: 2, marginLeft: 0.5 }}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {`By ${selected.label}`}
      </Button>
      <StyledMenu
        id="sort-menu"
        MenuListProps={{
          'aria-labelledby': 'sort-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((el, i) => [
          <MenuItem
            onClick={() => handleSelect(el.id)}
            disableRipple
            selected={el.id === selected.id}
          >
            {el.label}
            {el.reverseOrder ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
          </MenuItem>,
          i !== options.length - 1 && i % 2 !== 0 && <Divider sx={{ my: 0 }} />,
        ])}
      </StyledMenu>
    </div>
  )
}
