// Type of authority (code AA) = Type of buyer

const searchable = [
  {
    value: '1',
    label: 'Ministry and any other national or federal authorities',
  },
  { value: '3', label: 'Regional and local authorities' },
  { value: '4', label: 'Utilities entity' },
  { value: '5', label: 'EU institution/agency or international organisation' },
  { value: '6', label: 'Body governed by public law' },
  { value: 'N', label: 'National and federal agencies' },
  { value: 'R', label: 'Regional and local agencies' },
  // { value: '8 or 9 or Z', label: 'Other' },
]

const nonSearchable = [
  { value: '8', label: 'Other' },
  { value: '9', label: 'Other' },
  { value: 'Z', label: 'Other' },
]

const model = [...searchable, ...nonSearchable]

export default searchable
export { model as AA }
