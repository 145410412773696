// Type of contract (code NC)

const searchable = [
  { value: '1', label: 'Works' },
  { value: '2', label: 'Supplies' },
  { value: '3', label: 'Combined' },
  { value: '4', label: 'Services' },
  // { value: '8 or 9 or Z', label: 'Not defined' },
]

const nonSearchable = [
  { value: '8', label: 'Not defined' },
  { value: '9', label: 'Not defined' },
  { value: 'Z', label: 'Not defined' },
]

const model = [...searchable, ...nonSearchable]

export default searchable
export { model as NC }
