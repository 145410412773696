import OPTIONS from 'src/API/models/pr'
import MultipleSelection from './MultipleSelection'

export default function TypeOfProcedure() {
  return (
    <MultipleSelection
      label="Type of procedure"
      anyLabel="All types"
      maxItems={1}
      options={OPTIONS}
      searchParam="PR"
    />
  )
}
