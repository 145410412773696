import { Grid, useMediaQuery } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

import SearchScope from '../SearchScope'
import SearchResults from '../SearchResults'
import RefineSearch from '../RefineSearch'

const ExpandedGrid = styled(Grid)(() => ({
  flexGrow: 1,
}))

export default function Main() {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <ExpandedGrid container spacing={2}>
      <Grid item xs={12} md={3} lg={2} order={1}>
        <SearchScope />
      </Grid>
      <Grid item xs={12} md={8} lg={6} xl={4} order={3}>
        <SearchResults />
      </Grid>
      <Grid item md={3} sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }} order={4}>
        {' '}
      </Grid>
      <Grid item xs={12} md={8} lg={4} order={matches ? 5 : 5}>
        <RefineSearch />
      </Grid>
    </ExpandedGrid>
  )
}

/*
(
    <ExpandedGrid container spacing={2}>
      <Grid item xs={12} lg={2} order={1}>
        <SearchScope />
      </Grid>
      <Grid item xs={12} md={9} lg={5} order={3}>
        <SearchResults />
      </Grid>
      <Grid item xs={12} md={3} lg={2} order={matches ? 4 : 4}>
        <RefineSearch />
      </Grid>
    </ExpandedGrid>
  )
*/
