/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react'

import {
  Container,
  Chip,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
  Button,
  useMediaQuery,
} from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import CloseIcon from '@mui/icons-material/Close'

import { styled, alpha, useTheme } from '@mui/material/styles'

import { useSearchParamUpdater } from 'src/Context/SearchContext'

import ScopeDrawer from '../Drawer'

const ScopeChip = styled(Chip)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  '&.MuiChip-colorPrimary:hover': {
    backgroundColor: alpha(theme.palette.primary.dark, 1),
  },
  '&.MuiChip-colorInfo:hover, &.MuiChip-colorInfo.Mui-focusVisible': {
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
  },
}))

export default function MultipleSelection({
  options = [],
  defaultValue = [],
  maxItems = 3,
  anyLabel,
  label,
  searchParam,
  fullWidth = false,
}) {
  const [values, setValues] = useState(defaultValue)
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (event) => {
    const {
      target: { checked, value },
    } = event
    setValues((prev) => (checked ? [...prev, value] : prev.filter((item) => item !== value)))
  }

  const handleRemove = (value) => {
    setValues((prev) => prev.filter((item) => item !== value))
  }

  const handleAny = () => {
    setValues([])
    setIsOpen(false)
  }

  const updateFilter = useSearchParamUpdater(searchParam)

  useEffect(() => {
    updateFilter(values)
  }, [updateFilter, values])

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const DESIRED_ROWS = Math.ceil(options.length / 5)
  const ITEMS_PER_ROW = Math.ceil(options.length / DESIRED_ROWS)

  const selectionLabel =
    values.length > maxItems
      ? options
          .filter((country) => values.slice(0, maxItems).includes(country.value))
          .map((country) => country.label)
          .join(', ')
          .concat(` + ${values.length - maxItems}`)
      : options
          .filter((country) => values.includes(country.value))
          .map((country) => country.label)
          .join(', ')

  return (
    <>
      <section
        style={{
          maxWidth: '100%',
          width: fullWidth ? '100%' : 'auto',
          marginBottom: 16,
          marginLeft: 0,
        }}
      >
        <Typography variant="subtitle2" display="block" gutterBottom color="primary">
          {label}
        </Typography>
        {values.length ? (
          <>
            {values.length > maxItems ? (
              <ScopeChip
                color="primary"
                onDelete={handleAny}
                size="small"
                label={selectionLabel}
                onClick={handleOpen}
                sx={{ maxWidth: '100%' }}
              />
            ) : (
              values.map((val) => (
                <ScopeChip
                  color="primary"
                  onDelete={() => handleRemove(val)}
                  key={val}
                  size="small"
                  label={options.find((el) => el.value === val).label}
                  onClick={handleOpen}
                  sx={{ maxWidth: '100%', marginRight: 0.5 }}
                />
              ))
            )}
          </>
        ) : (
          <ScopeChip
            color="info"
            size="small"
            label={anyLabel}
            icon={<KeyboardArrowDownIcon />}
            onClick={handleOpen}
            sx={{ maxWidth: '100%' }}
          />
        )}
      </section>
      <ScopeDrawer anchor="bottom" open={isOpen} onClose={handleClose}>
        <Container maxWidth="xl">
          <Typography variant="subtitle1" color="info">
            Set the scope
          </Typography>
          <Stack
            direction={matches ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ marginBottom: 4 }}
          >
            <Typography variant="h5" color="info" gutterBottom>
              {label}
            </Typography>
            <section style={matches ? { alignSelf: 'flex-end', textAlign: 'right' } : null}>
              <Button
                disableElevation
                variant="contained"
                endIcon={<ClearAllIcon />}
                onClick={handleAny}
              >
                {anyLabel}
              </Button>
              <Button
                disableElevation
                variant="contained"
                endIcon={<CloseIcon />}
                onClick={handleClose}
              >
                Close
              </Button>
            </section>
          </Stack>
          <Grid container alignItems="flex-start">
            {[0, 1, 2].map((group) => (
              <Grid item xs={12} sm={4} lg={4} key={group} container>
                {[0, 1].map((col) => (
                  <Grid item lg={6} key={col}>
                    {options
                      .filter(
                        (el, i) =>
                          i >= group * ITEMS_PER_ROW * 2 + col * ITEMS_PER_ROW &&
                          i < group * ITEMS_PER_ROW * 2 + (col + 1) * ITEMS_PER_ROW
                      )
                      .map((el) => (
                        <FormControlLabel
                          sx={{ width: '100%' }}
                          control={
                            <Checkbox
                              checked={values.includes(el.value)}
                              onChange={handleChange}
                              value={el.value}
                              color="info"
                            />
                          }
                          key={el.value}
                          label={el.label}
                        />
                      ))}
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </Container>
      </ScopeDrawer>
    </>
  )
}
