// Type of procedure (code PR)

const searchable = [
  { value: '1', label: 'Open procedure' },
  { value: '2', label: 'Restricted procedure' },
  { value: '3', label: 'Accelerated restricted procedure' },
  { value: '4', label: 'Negotiated procedure' },
  { value: '6', label: 'Accelerated negotiated procedure' },
  { value: 'A', label: 'Direct award' },
  { value: 'B', label: 'Competitive procedure with negotiation' },
  { value: 'C', label: 'Competitive dialogue' },
  { value: 'E', label: 'Concession award procedure' },
  { value: 'F', label: 'Concession award without prior concession notice' },
  { value: 'G', label: 'Innovation partnership' },
  { value: 'T', label: 'Negotiated without a prior call for competition' },
  { value: 'V', label: 'Contract award without prior publication' },
  // { value: 'Z or 8 or 9', label: 'Other' },
]

const nonSearchable = [
  { value: '8', label: 'Other' },
  { value: '9', label: 'Other' },
  { value: 'Z', label: 'Other' },
]

const model = [...searchable, ...nonSearchable]

export default searchable
export { model as PR }
