// Countries (code CY)

const searchable = [
  { value: 'AT', label: 'Austria' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czechia' },
  { value: 'DE', label: 'Germany' },
  { value: 'DK', label: 'Denmark' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ES', label: 'Spain' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GR', label: 'Greece' },
  { value: 'HR', label: 'Croatia' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IT', label: 'Italy' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'LV', label: 'Latvia' },
  { value: 'MT', label: 'Malta' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'RO', label: 'Romania' },
  { value: 'SE', label: 'Sweden' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SK', label: 'Slovakia' },

  { value: 'IS', label: 'Iceland' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'NO', label: 'Norway' },

  { value: 'AL', label: 'Albania' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'RS', label: 'Serbia' },
  { value: 'TR', label: 'Turkey' },

  { value: 'AD', label: 'Andorra' },
]

export default searchable
export { searchable as CY }
